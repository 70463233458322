import { getRuntimeConfig } from "..";
import { Environment } from "../runtimeConfig.types";
import { DevelopmentFlag, StaticFeatureFlag } from "./flags";

export {
  DevelopmentFlag,
  StaticFeatureFlag,
  DynamicFeatureFlag,
  DynamicFeatureConfigFlag,
} from "./flags";

/**
 * Is the app running in development mode?
 *
 * @NOTE this can mean running on the local development server, running on TEST
 * or running on DEV.
 */
export const isDevModeEnabled = (): boolean => {
  const { env } = getRuntimeConfig();
  const developmentEnvironments: Environment[] = [
    "local",
    "development",
    "test",
  ];
  return developmentEnvironments.includes(env);
};

/**
 * @NOTE this means that the app is running on the local development server
 * provided by CRA.
 */
export const isLocalDevModeEnabled = (): boolean => {
  const { env } = getRuntimeConfig();
  return env === "local";
};

/**
 * Is the app running in production mode?
 */
export const isProduction = (): boolean => {
  const { env } = getRuntimeConfig();
  return env === "production";
};

/**
 * Check a static feature flag value.
 *
 * @NOTE - Use this function if you need to check a static feature flag BEFORE
 * the redux store has been initialized. Otherwise you can use
 * `selectIsStaticFeatureActive` which will make it easier to create tests
 * with the feature flag enabled / disabled.
 */
export const isStaticFeatureActive = (flag: StaticFeatureFlag): boolean => {
  return !!getRuntimeConfig().features?.[flag];
};

/**
 * Check a development feature flag value.
 */
export const isDevelopmentFeatureActive = (flag: DevelopmentFlag): boolean => {
  return !!getRuntimeConfig().features?.[flag];
};
