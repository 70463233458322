import { isNil } from "ramda";
import { CollaboardApiError } from "../errors/collaboardApiError";
import { ExternalResourceError } from "../errors/externalResourceError";
import { SignalRError } from "../errors/signalRError";
import { isDefined } from "./utils";

export const networkErrorRegex = /Network Error/i;
export const wsAbnormalClosureRegex = /WebSocket closed with status code: 1006/i;

/**
 * @NOTE These functions should work with `Cloneable` errors as well
 */

export const isError = (error: unknown): error is Error => {
  return (
    !isNil(error) &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error instanceof Error || ((error as any).name && (error as any).message))
  );
};

export const isDOMException = (error: unknown): error is DOMException => {
  return error instanceof DOMException;
};

export const isTaintedCanvasError = (error: unknown): error is DOMException => {
  return isDOMException(error) && error.name === "SecurityError";
};

export const isHTTPResponseError = (error: unknown): error is Response => {
  return !!(error as Response).statusText;
};

export const isAPIError = <
  ResponseType extends ApiBaseResponse = ApiBaseResponse
>(
  error: unknown
): error is CollaboardApiError<ResponseType> => {
  return isError(error) && error.name === "CollaboardApiError";
};

/**
 * @deprecated Some error handlers seem to directly access ErrorCode field, which should be however under
 * `error.details.errorCode` in `CollaboardApiError`.
 *
 * @TODO Check what actually happens when you come across this function and refactor using a more
 * standard error object.
 */
export const isApiErrorWithCode = (error: unknown): error is ApiError => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return isDefined((error as any).ErrorCode);
};

export const isAPIErrorObject = <
  ResponseType extends ApiBaseResponse = ApiBaseResponse
>(
  error: unknown
): error is CollaboardApiErrorObject<ResponseType> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return error && (error as any).message && (error as any).details;
};

export const isExternalResourceError = (
  error: unknown
): error is ExternalResourceError => {
  return isError(error) && error.name === "ExternalResourceError";
};

export const isSignalRError = (error: unknown): error is SignalRError => {
  return isError(error) && error.name === "SignalRError";
};

export const isNetworkError = (error: unknown): error is SignalRError => {
  return isError(error) && networkErrorRegex.test(error.message);
};

export const isWSAbnormalClosure = (error: unknown): error is SignalRError => {
  return isError(error) && wsAbnormalClosureRegex.test(error.message);
};

/**
 * Return a string from an error.
 * @NOTE Error.prototype.toString is a little different as it is prefixes the error name in the string.
 * That's why we don't just use `${error}`.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/toString#description
 */
export const stringifyError = (error: unknown): string => {
  return isError(error) ? error.message : `${error}`;
};
